import {Component} from "react";
import {Container, Header, Grid} from "semantic-ui-react";
import LocationLogo from "../icons/travelRoomingLogo.png"
import calendarLogo from "../icons/travelRoomingSearch.png"
import networkLogo from "../icons/network.png"
import React from "react";
import GridIcon from "./FirstAnswerContainer/GridIcon";

const containerStyle = {
    marginTop: "50px",
}


const marginTop = { marginTop: "10px" };


const headerFontStyle = {
    fontFamily: 'AppleGothic',
    color:'rgb(38,135,126)'
};

class FirstAnswerContainer extends Component {
    render() {
        return (
            <Container id="secondAnswer" style={containerStyle}>
                <Header textAlign="center" as="h2" content="How does rooming work?" style={headerFontStyle} />
                <Grid container stackable style={marginTop} columns={3}>
                    <Grid.Column>
                        <GridIcon
                            image={networkLogo}
                            headerContent="Access your network"
                            message="Unlock the potential of your network and see where all of your friends are living right now."
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <GridIcon
                            image={calendarLogo}
                            headerContent="Find their availability"
                            message="Find out when people in the rooming network can host you and book a room on our platform."
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <GridIcon
                            image={LocationLogo}
                            headerContent="Stay in or offer a room"
                            message="Save money by staying with people you know and helping other roomers to explore your city."
                        />
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

export default FirstAnswerContainer;

/*<Grid.Row columns={3}>
    <Grid.Column>
        <Image style={imgWidthStyle} src={networkLogo} name="plus"/>
    </Grid.Column>
    <Grid.Column>
        <Image style={imgWidthSubStyle} src={LocationLogo} name="location arrow"/>
    </Grid.Column>
    <Grid.Column>
        <Image style={imgWidthStyle} src={calendarLogo} name="calendar alternate outline"/>
    </Grid.Column>
</Grid.Row>
<Grid.Row columns={3}>
<Grid.Column>
<Header as="h3" content="Access your network" style={h3Style}/>
</Grid.Column>
<Grid.Column>
<Header as="h3" content="Stay in or offer a room" style={h3Style}/>
</Grid.Column>
<Grid.Column>
<Header as="h3" content="Find their availability" style={h3Style}/>
</Grid.Column>
</Grid.Row>
<Grid.Row style={textRowFontStyle} columns={3}>
    <Grid.Column>
        <p style={pStyle}>Unlock the potential of your network and see where all of your friends are living right now.</p>
    </Grid.Column>
    <Grid.Column>
        <p style={pStyle}>Save money by staying with people you know and helping other roomers to explore your city.</p>
    </Grid.Column>
    <Grid.Column>
        <p style={pStyle}>Find out when people in the rooming network can host you and book a room on our platform.</p>
    </Grid.Column>
</Grid.Row>*/