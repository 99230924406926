import {Fragment, useState} from "react";
import {Header, Image} from "semantic-ui-react";
import React from "react";

const logoImgStyle = {
    maxHeight:'150px',
    margin: 'auto',
};

const logoImgHoverStyle = {
    maxHeight:'150px',
    margin: 'auto',
    boxShadow: '1px 5px 0px rgb(174, 182, 188)',
};

const hStyle = {
    marginBottom: '5px',
}

const hHoverStyle = {
    ...hStyle,
    marginBottom: '5px',
    color: "rgb(45, 215, 188)",
}

const hSubStyle = {
    marginBottom: 'unset',
    marginTop: 'unset',
}

const hSubHoverStyle = {
    ...hSubStyle,
    color: "rgb(45, 215, 188)",
}

const pStyle = {
    marginBottom: 'unset',
    marginTop: '5px',
    color: 'rgb(80,80,80)',
}

const pHoverStyle = {
    ...pStyle,
    color: "rgb(45, 215, 188)",
}

export const PictureLinkItem = (context) => {
    const [isHover, setHover] = useState(false);

    return (
        <Fragment>
            <a
                href={context.href}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Image
                    circular
                    style={isHover ? logoImgHoverStyle : logoImgStyle}
                    src={context.src}
                />
                <Header as="h5" content={context.name} style={isHover ? hHoverStyle : hStyle}/>
                <Header as="h5" content={context.message} style={isHover ? hSubHoverStyle : hSubStyle}/>
                <Header as="h5" style={isHover ? pHoverStyle : pStyle}>{context.submessage}</Header>
            </a>
        </Fragment>
    );
};

