import React, {Component} from 'react';
import {Button, Header, Container} from 'semantic-ui-react';

const containerStyle = {
    marginBottom: "25px",
    marginTop: "50px",
    textAlign: "center",
    paddingLeft : "10%",
    paddingRight : "10%",
};

const buttonStyle = { backgroundColor: "rgb(0, 199, 179)", color: "white"};

const getStartedTitleStyle = {fontFamily: 'AppleGothic', color: 'rgb(38,135,126)'};

const signUpHeaderStyle = {fontFamily: 'AppleGothic', color: "rgb(80, 80, 80)"};

class GetStartedFormContainer extends Component {
    render() {
        return (
            <Container style={containerStyle}>
                    <Header as="h1" content="Ready to get started?" style={getStartedTitleStyle} />
                    <Header as="h3" style={signUpHeaderStyle} content="Sign up to newsletter now!" />
                    <Button onClick={this.props.scrollToTop} style={buttonStyle} content="Sign up" />
            </Container>
         )
    }
}

export default GetStartedFormContainer;