import React, {Component} from 'react';
import {Button, Form, Grid} from 'semantic-ui-react';
import axios from 'axios';

export const inputBorderStyle = { border: "solid 1.5px rgb(38,135,126)" };

export const buttonStyle = {
    backgroundColor: "rgb(0, 199, 179)",
    color: "white",
    minWidth: "100px",
};

const formStyle = {textAlign: "left", marginTop: "5%"};

const textAreaStyle = {width: "100%", borderRadius: "10px"};

const pStyle = {
    success: {
        color: 'rgb(38, 135, 126)',
        textAlign: 'center',
        fontWeight:'bold',
        marginTop: '25px',
    },
    error: {
        color: 'red',
        marginTop: '4px',
        marginLeft: '15px',
    },
};


class GetMemberMailForm extends Component {
    state = {
        mail: '',
        showInput: true,
        loading: false,
        emailError: false,
    }

    axiosRequestToFormSpark = mail => this.setState({loading: true}, () => axios.post(`https://submit-form.com/9RFolaLZjQ_dvAXzJt2he`, { mail })
        .then(() => this.setState({loading: false, showInput: false}))
    )

    handleChange = event => {
        this.setState({ mail: event.target.value });
    }

    handleSubmit = event => {
        event.preventDefault();

        const mail = this.state.mail;
        const pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        
        if (this.state.mail.toString().match(pattern))
            this.axiosRequestToFormSpark(mail);
        else
            this.setState({emailError: true});
  }

    hideInput = () => {
        this.setState({showInput: false});
    }

    render() {
        return (
            <Form style={formStyle} onSubmit={this.handleSubmit}>
                <Form.Field style={textAreaStyle}>
                    <Grid stackable>
                    {this.state.showInput ?
                        <React.Fragment>
                            <Grid.Column computer={12} mobile={10} tablet={9}>
                                <input
                                    ref={this.props.inputRef}
                                    name='email'
                                    style={inputBorderStyle}
                                    placeholder='Enter
                                    your
                                    email'
                                    onChange={this.handleChange}
                                />
                                {this.state.emailError && this.state.showInput && <p style={pStyle.error}>Invalid email address</p>}
                            </Grid.Column>
                            <Grid.Column computer={4} mobile={6} tablet={7}>
                                <Button
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                    fluid
                                    style={buttonStyle}
                                    content="Sign
                                    up"
                                    type="submit"
                                    //onClick={this.hideInput}
                                />
                            </Grid.Column>
                        </React.Fragment>
                        : <h2 style={pStyle.success}>Thanks for registering. You will hear from us soon.</h2>
                    }
                    </Grid>
                </Form.Field>

            </Form>
        );
    }
}

export default GetMemberMailForm;