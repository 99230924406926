import React, {Component} from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import Kate from "../picture/kate.png";
import Maria from "../picture/maria.png";
import Chiara from "../picture/chiara.png";
import Kevin from "../picture/kevin.png";
import Sinane from "../picture/sinane.png";
import {PictureLinkItem} from "./LastAnswerContainer/PictureLinkItem";


const containerStyle = {
    backgroundColor: "rgb(249,249,249)",
    width: "100%",
    marginTop: "25px",
    textAlign: "center",
    paddingTop: "50px",
    paddingLeft : "10%",
    paddingRight : "10%",
};


const gridStyle = {
    marginTop: "50px",
};

const textStyle1 = {
    fontFamily: 'AppleGothic',
    color:'rgb(38,135,126)',
};

const textStyle2 = {
    width: "100%",
    fontFamily: 'AppleGothic',
    color: "rgb(80, 80, 80)",
    fontSize: "1.3em",
    textAlign: "justify"
};

const secondRowPaddingStyle = {
    paddingLeft: "10%",
    paddingRight: "10%",
    marginBottom: "5%",
};

const text1 = 'Who is behind rooming?';

const text2 = `At rooming, we are international by nature and travel lovers by heart. 
    We have all encountered the challenge of finding budget-friendly and trustworthy accommodation and we found out that our greatest resource in solving that were our personal connections.`;

class LastAnswerContainer extends Component {
    state = {
        imgHover: false,
    };

    render() {
        return (
          <Container id="thirdAnswer" style={containerStyle}>
            <Header as="h2" content={text1} style={textStyle1}/>
            <Grid container stackable style={gridStyle}>
                <Header as="h5" content={text2} style={textStyle2}/>
                <Grid.Row columns={3}>
                      <Grid.Column>
                          <PictureLinkItem
                              href="https://www.linkedin.com/in/katewhyteie/"
                              src={Kate}
                              message="HEC Paris"
                              submessage="Marketing and Product Management"
                              name="Kate Whyte"
                          />
                      </Grid.Column>
                      <Grid.Column>
                          <PictureLinkItem
                              href="https://www.linkedin.com/in/mariafcadena/"
                              src={Maria}
                              message="HEC Paris & Ecole Polytechnique"
                              submessage="Data Science and Customer Analytics"
                              name="Maria Cadena"
                          />
                      </Grid.Column>
                      <Grid.Column>
                          <PictureLinkItem
                              href="https://www.linkedin.com/in/chiarasaggiomo/"
                              src={Chiara}
                              message="HEC Paris"
                              submessage="Consulting and Financial Analysis"
                              name="Chiara Saggiomo"
                          />
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={secondRowPaddingStyle} columns={2}>
                      <Grid.Column>
                          <PictureLinkItem
                              href="https://www.linkedin.com/in/kikoane/"
                              src={Kevin}
                              message="Ecole 42"
                              submessage="Software Development"
                              name="Kevin Nguyen"
                          />
                      </Grid.Column>
                      <Grid.Column>
                          <PictureLinkItem
                              href="https://www.linkedin.com/in/sinane-si-mohammed-10b545164/"
                              src={Sinane}
                              message="Ecole 42"
                              submessage="Software Development"
                              name="Sinane Si Mohammed"
                          />
                      </Grid.Column>
                </Grid.Row>
            </Grid>
          </Container>
        )
    }
}

export default LastAnswerContainer;