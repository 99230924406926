import {Component} from "react";
import {Container, Icon} from "semantic-ui-react";
import React from "react";

const containerStyle = {
    backgroundColor: "rgb(249,249,249)",
    width: "100%",
    textAlign: "center",
    paddingTop: "20px",
    paddingLeft : "20%",
    paddingRight : "20%",
    paddingBottom: "20px"
}

const wordStyle = {
    fontFamily: "AppleGothic",
    color: "rgb(38, 135, 126)",
    fontSize: "1rem",
    fontWeight: '300',
};

const iconColorStyle = {
    color: "rgb(0, 199, 179)",
    //width: "100%",
}

class RoomingFooter extends Component {
    render() {
        return <Container style={containerStyle}>
            <p style={wordStyle}>Follow us:</p>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/RoomingAroundTheWorld/">
                <Icon style={iconColorStyle} size="big" link name="facebook"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/rooming-around-the-world/">
                <Icon style={iconColorStyle} size="big" link name="linkedin"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/roomingaroundtheworld/">
                <Icon style={iconColorStyle} size="big" link name="instagram"/>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RoomingAround">
                <Icon style={iconColorStyle} size="big" link name="twitter"/>
            </a>
        </Container>;
    }
}

export default RoomingFooter;