import {Component} from "react";
import {Container, Header, Image, Menu, Button, Responsive} from "semantic-ui-react";
import roomingLogo from "../icons/newRoomingLogo.png";
import React from "react";
import PropTypes from 'prop-types'

const navbarMenuStyle = {
    width: "100%",
};

const questionHeaderStyle = {
    marginTop: "10px",
    fontFamily: 'AppleGothic',
    fontSize: '1.25rem',
    fontWeight: '300',
    color: "rgb(80,80,80)",
};

const itemWithNoBorder = {
    border: 'none',
};

const questionHeaderHoverStyle = {
    marginTop: "10px",
    fontFamily: 'AppleGothic',
    fontSize: '1.25rem',
    fontWeight: '300',
    color: 'rgb(45, 215, 188)',
};

const buttonStyle = {
    backgroundColor: "rgb(0 199 179)",
    color: "white",
    width: "20%",
    minWidth: "100px",
};

const imageWidthStyle = { width: "150px" };

const getWidth = () => {
  const isSSR = typeof window === 'undefined'

  return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}

class DesktopContainer extends Component {
    state = {
        mouseOnQuestion: 0,
    };

    handleClickOnLogo = () => window.location.replace("https://rooming.fr.nf/");
    handleMouseOnQuestion = id => this.setState({ mouseOnQuestion: id });
    handleMouseOffQuestion = () => this.setState({ mouseOnQuestion: 0 });

    render() {
        const { children } = this.props
        return (
         <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>

            <Container id="top" fluid>
            <Menu id="top" fixed="top" style={navbarMenuStyle} stackable borderless widths={5}>
                <Menu.Item onClick={this.handleClickOnLogo} style={itemWithNoBorder}>
                    <Image style={imageWidthStyle} src={roomingLogo}/>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#firstAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(1)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 1 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="What is rooming about?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#secondAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(2)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 2 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="How does rooming work?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#thirdAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(3)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 3 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="Who is behind rooming?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <Button onClick={this.props.scrollToTop} style={buttonStyle} content="Sign Up" />
                </Menu.Item>
            </Menu>
        </Container>

        {children}
      </Responsive>
        );
    }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
    state = {
        mouseOnQuestion: 0,
    };

    handleClickOnLogo = () => window.location.reload();
    handleMouseOnQuestion = id => this.setState({ mouseOnQuestion: id });
    handleMouseOffQuestion = () => this.setState({ mouseOnQuestion: 0 });

    render() {
        const { children } = this.props

        return (
        <Responsive
        getWidth={getWidth}
        maxWidth={Responsive.onlyMobile.maxWidth}>
        <Container id="top" fluid>
            <Menu id="top" style={navbarMenuStyle} stackable borderless widths={5}>
                <Menu.Item onClick={this.handleClickOnLogo} style={itemWithNoBorder}>
                    <Image style={imageWidthStyle} src={roomingLogo}/>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#firstAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(1)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 1 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="What is rooming about?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#secondAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(2)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 2 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="How does rooming work?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <a href="#thirdAnswer">
                        <Header
                            onMouseOver={() => this.handleMouseOnQuestion(3)}
                            onMouseLeave={this.handleMouseOffQuestion}
                            style={this.state.mouseOnQuestion === 3 ? questionHeaderHoverStyle : questionHeaderStyle}
                            content="Who is behind rooming?"
                        />
                    </a>
                </Menu.Item>
                <Menu.Item style={itemWithNoBorder}>
                    <Button onClick={this.props.scrollToTop} style={buttonStyle} content="Sign Up" />
                </Menu.Item>
            </Menu>
        </Container>

                  {children}
      </Responsive>
    );
    }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const TitleContainer = (context, { children }) => (
  <div>
    <DesktopContainer scrollToTop={context.scrollToTop}>{children}</DesktopContainer>
    <MobileContainer scrollToTop={context.scrollToTop}>{children}</MobileContainer>
  </div>
)

TitleContainer.propTypes = {
  children: PropTypes.node,
}

export default TitleContainer;