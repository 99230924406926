import React, {Component} from 'react';
import { Container, Image, Grid, Header } from "semantic-ui-react";
import GetMemberMailForm from "./PresentationContainer/GetMemberMailForm";
import roomingCharacters from "../icons/roomingCharacters.png";

const containerStyle = {
    marginTop: '125px',
    marginBottom: '50px',
    width: "95%",
    paddingLeft: "8%",
    paddingRight: "8%",
};

const textMarginStyle = {
    fontFamily: 'AppleGothic',
    fontSize: "2.5rem",
    color: "rgb(80,80,80)",
    textAlign: "justify",
};

const textUnderTitleStyle = {
    ...textMarginStyle,
    marginTop: "20px",
    fontSize: "1.25 rem",
    
}

class PresentationContainer extends Component {
    render() {
        return <Container style={containerStyle} id="presentation">
            <Grid container stackable columns={2}>
                <Grid.Column>
                    <Header
                        as="h1"
                        style={textMarginStyle}
                        content='Your network is your key to travel all over Europe. Use it!'
                    />
                    <Header 
                        as="h2"
                        content="Become a roomer. Join our newsletter now and we will keep you posted on launch date and the next steps." 
                        style={textUnderTitleStyle}
                    />
                    <GetMemberMailForm inputRef={this.props.inputRef} />
                </Grid.Column>
                <Grid.Column>
                    <Image src={roomingCharacters} />
                </Grid.Column>
            </Grid>
        </Container>;
    }
}

export default PresentationContainer;