import {Component} from "react";
import { Grid, Container, Header, Image} from "semantic-ui-react";
import roomingMap from "../icons/roomingMap.png";
import React from "react";

const containerStyle = {
    width: "100%",
    textAlign: "center",
    paddingTop: "50px",
    paddingLeft : "10%",
    paddingRight : "10%",
    paddingBottom: '50px',
    backgroundColor: "rgb(249,249,249)",
}

const gridTextStyle = { fontSize: "1.3em", textAlign: "justify" }

const gridImageStyle = { paddingTop: "unset" }

const pStyle = {fontFamily: 'AppleGothic', color: "rgb(80, 80, 80)", fontSize: "1.2em"};

const h3Style = {fontFamily: 'AppleGothic', color:'rgb(38,135,126)', marginBottom: "30px"};

class SecondAnswerContainer extends Component {
    render() {
        return (
            <Container fluid id="firstAnswer" style={containerStyle}>
                <Header as="h2" content="What is rooming about?" style={h3Style}/>
                <Grid container stackable columns={2}>
                    <Grid.Column style={gridTextStyle}>
                        <p style={pStyle}>
                            Travelling is expensive... and we know it!
                        </p>
                        <p style={pStyle}>
                            Rooming is a room-sharing community that allows you to cut the cost of your trip by staying in your friend’s and their friend’s rooms.
                        </p>
                        <p style={pStyle}>
                            Become a member now and expand your chances to explore the world!
                        </p>
                    </Grid.Column>
                    <Grid.Column style={gridImageStyle}>
                        <Image src={roomingMap}/>
                    </Grid.Column>
                </Grid>
            </Container>
        );
    }
}

export default SecondAnswerContainer;