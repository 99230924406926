import React from 'react';
import {Grid, Header, Image} from "semantic-ui-react";

const h3Style = { fontFamily: 'AppleGothic', color:'rgb(38,135,126)', fontSize: "1.3em"};
const pStyle = { fontFamily: 'AppleGothic', color: "rgb(80, 80, 80)", fontSize: "1.1em"};
const imageMaxWidthStyle = {maxHeight: "275px"};
const gridNoMarginStyle = {marginRight: "14px"};

const GridIcon = (context) => {
    return (
        <Grid centered style={gridNoMarginStyle} >
            <Grid.Row>
                <Image style={imageMaxWidthStyle} src={context.image}/>
            </Grid.Row>
            <Grid.Row>
                <Header as="h2" content={context.headerContent} style={h3Style}/>
            </Grid.Row>
            <Grid.Row>
                <p style={pStyle}>{context.message}</p>
            </Grid.Row>
        </Grid>
    );
}

export default GridIcon;