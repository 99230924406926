import React, { Component } from 'react';
import ReactGA from 'react-ga';
import TitleContainer from "./components/TitleContainer";
import PresentationContainer from "./components/PresentationContainer";
import FirstAnswerContainer from "./components/FirstAnswerContainer";
import SecondAnswerContainer from "./components/SecondAnswerContainer";
import LastAnswerContainer from "./components/LastAnswerContainer";
import GetStartedFormContainer from "./components/GetStartedFormContainer";
import RoomingFooter from "./components/RoomingFooter";

class App extends Component {
    constructor(props) {
        super(props)
        this.scrollRef = React.createRef()   // Create a ref object
        this.inputRef = React.createRef();
    }

    scrollToTop = () => {
        const scrollOptions = {top: 0, left: this.scrollRef.current.offsetTop, behavior: "smooth"}
        const promise = new Promise(resolve => resolve(window.scrollTo(scrollOptions)));
        promise.then(() => setTimeout(() => this.inputRef.current.focus(), 500));
    }

    render() {
        return (
            <div ref={this.scrollRef}>
                <TitleContainer scrollToTop={this.scrollToTop}/>
                <PresentationContainer inputRef={this.inputRef}/>
                <SecondAnswerContainer/>
                <FirstAnswerContainer/>
                <LastAnswerContainer/>
                <GetStartedFormContainer scrollToTop={this.scrollToTop}/>
                <RoomingFooter/>
            </div>
        );
  }
}

function initializeReactGA() {
    ReactGA.initialize('UA-135192812-1');
    ReactGA.pageview('/homepage');
}

initializeReactGA();

export default App;
